









































































































































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { isEmpty } from "lodash";
import VModal from "vue-js-modal";

Vue.use(VModal);

import {
  CustomTable,
  CustomInput,
  SvgIcon,
  ApproveBatchModal,
  RejectBatchModal,
  ProcessedBatchModal,
  OTPModal,
} from "@components";
import { getBatchStatusLabel, unixToDateString } from "@/utils/common";
import { ListBatchResponse, Batch } from "@/proto/batch/all_pb";

const transactionsModule = namespace("transactions");

interface TFAPayload {
  code: string;
  batchID: string;
}

@Component({
  components: {
    CustomTable,
    CustomInput,
    SvgIcon,
    ApproveBatchModal,
    RejectBatchModal,
    ProcessedBatchModal,
    OTPModal,
  },
})
export default class TransactionsList extends Vue {
  @transactionsModule.Action("getBatchTxns")
  getBatchTxns!: (batch: Array<string>) => ListBatchResponse.AsObject;
  @transactionsModule.Action("processTFA") processTFA!: (
    payload: TFAPayload
  ) => void;
  @transactionsModule.Action("resendTFA") resendTFA!: (batchID: string) => void;

  searchTerm = "";
  loading = true;
  batchTxns: Array<Batch.AsObject> = [];
  batch: Batch.AsObject = {} as Batch.AsObject;
  notFound = false;
  otpError = "";
  error = "";
  resending = false;

  mounted() {
    this.fetchBatchTxns();
  }

  async fetchBatchTxns() {
    const result = await this.getBatchTxns([this.batchID]);
    this.batchTxns = result.batchList;

    const batch = this.batchTxns.find((txn) => txn.id === this.batchID);

    if (batch) {
      this.batch = batch;
    } else {
      this.notFound = true;
    }

    this.loading = false;
  }

  get batchStatus() {
    return getBatchStatusLabel(this.batch.status);
  }

  get isPending() {
    return this.batch.status === "Created";
  }

  get isApproved() {
    return this.batch.status === "Approved";
  }

  get batchStatusClass() {
    return `batch-${this.batchStatus.toLowerCase()}`;
  }

  get getSettlementRail() {
    return this.batch.transactionsList[0].settlementrail;
  }

  get transactionsList() {
    if (isEmpty(this.batch)) return [];
    const batch = this.batch as any;
    return batch.transactionsList.map((txn: any) => {
      return {
        reference: txn.transactionid,
        createdDate: this._transactionCreatedDate(txn.date.seconds),
        amount: `PHP ${this._getAmount(txn.amount.num)}`,
      };
    });
  }

  get batchID(): string {
    return this.$route.params.batchID;
  }

  _getAmount(amt: string): string {
    const amount = parseFloat(amt) / 100;
    return amount.toFixed(2);
  }

  _transactionCreatedDate(createdDate: number): string {
    if (!createdDate) return "N/A";
    return unixToDateString(createdDate);
  }

  onApproveBatch() {
    this.$modal.show("approve-txn-modal");
  }

  onCancelApproval() {
    this.$modal.hide("approve-txn-modal");
  }

  onCancelReject() {
    this.$modal.hide("reject-txn-modal");
  }

  onConfirmReject() {
    this.$modal.hide("reject-txn-modal");
    this.fetchBatchTxns();
  }

  onConfirmApproval() {
    this.fetchBatchTxns();
    this.$modal.hide("approve-txn-modal");
    this.showOTPModal();
  }

  onProcessedModalClose() {
    this.$modal.hide("processed-txn-modal");
  }

  onOTPModalClose() {
    this.$modal.hide("otp-modal");
  }

  async onOTPCodeSubmit(code: string) {
    try {
      await this.processTFA({
        code: code,
        batchID: this.batchID,
      });
      this.$modal.hide("otp-modal");
      this.$modal.show("processed-txn-modal");
    } catch (error) {
      const err = error as Error;
      this.otpError = err.message;
    }

    this.fetchBatchTxns();
  }

  async onResendOTP() {
    this.resending = true;

    try {
      await this.resendTFA(this.batchID);
      this.showOTPModal();
    } catch (error) {
      const err = error as Error;
      this.error = err.message;
    } finally {
      this.resending = false;
    }
  }

  showOTPModal() {
    this.otpError = "";
    this.$modal.show("otp-modal");
  }

  onRejectBatch() {
    this.$modal.show("reject-txn-modal");
  }

  get columns() {
    return [
      {
        field: "reference",
        label: "Reference",
        sortable: false,
      },
      {
        field: "createdDate",
        label: "Created Date",
        sortable: false,
      },
      {
        field: "amount",
        label: "Amount",
        sortable: false,
      },
    ];
  }
}
